.small-card{
    background: rgba( 255, 255, 255, 0.7 );
    box-shadow: 10px 10px 32px 0 rgba( 31, 38, 135, 0.05 );
    border-radius:30px;
    min-height:80px;
    display:flex;
    align-items:center;
    justify-content: space-between;
  }

  .chartarea{
    height:50%;
    display:flex;
    align-items:center;
    justify-content:center;
  }

  .votebutton{
    background-image: linear-gradient(to right, #21D2FF 0%, #F17AE9  51%, #21D2FF  100%);
    padding: 10px 20px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 10px;
    border:0px;
    font-weight:600;
    font-size:12px;
    box-shadow: 0 0 20px rgba( 31, 38, 135, 0.1 );
    margin-left:auto;
  }

  .vote-titlearea{
    display:flex;
    align-items:center;
    justify-content:left;
    width:80%;
    margin-top:30px;
  }

  .vote-title{
    font-weight:600;
    font-size:24px;
  }

  .tokenamount{
    width:50px;
    text-align:center;
    border-width: 0 0 1px;
    border-color: rgb(192, 192, 192);
    background-color:transparent;
  }
  
  .tokenname{
    margin-left:20px;
    font-weight:600;
  }

  .input-wrapper{
    margin-right:20px;
  }

  .input-wrapper::after {
    content: "%";
  }

.pollcards{
  margin-top:30px;
}

.card-wrapper{
  display: grid;
  grid-template-columns: repeat(auto-fill, 30%);
  justify-content: space-between;
  grid-gap: 10px;
  padding:20px;
}
  
.right-bar{
  width:100%;
  height:100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

  .small-card-title{
    display: flex;
    align-items:center;
    flex-direction: row;
    margin-top:10px;
  }
  .small-card-progress{
    display: flex;
    align-items:flex-start;
    flex-direction: column;
    width:90%;
    margin-left:20px;
    margin-top:10px;
    margin-bottom:10px;
    font-size:12px;
  }

  .small-card-text{
    margin-left:20px;
    font-size:14px;
  }

  .token-logo-image{
    width:48px;
    height:48px;
    border-radius:50%;
    box-shadow: 5px 5px 10px 0 rgba( 31, 38, 135, 0.2 );
    margin-top:auto;
    margin-bottom:auto;
  }

  .pollslider{
    margin-left:20px;
    width:90%;
    display:flex;
    flex-direction:row;
    align-items:center;
  }
  @media only screen and (max-width: 800px) {
  .card-wrapper{
    display: grid;
    grid-template-columns: repeat(auto-fill, 45%);
    justify-content: space-between;
    grid-gap: 10px;
    padding:20px;
  }
}